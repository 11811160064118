import { CardActions, Collapse, IconButton, makeStyles, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import clsx from 'clsx';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { red } from '@material-ui/core/colors';
// eslint-disable-next-line import/no-anonymous-default-export

const useStyles = makeStyles((theme) => ({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 0,
      paddingTop: '56.25%', // 16:9
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    avatar: {
      backgroundColor: red[500],
    },
  }));

// eslint-disable-next-line import/no-anonymous-default-export
export default ({title, children, expanded = false})=>{
    const classes = useStyles();
    const [expand, setExpand] = useState(expanded);
    const handleExpand = ()=>{
        setExpand(!expand);
    }
    return(
        <>
            <CardActions disableSpacing>
                <Typography variant='h5'>{title}</Typography>
                <IconButton
                    className={clsx(classes.expand, {[classes.expandOpen]: expand})}
                    onClick={handleExpand}
                >
                    <ExpandMoreIcon />
                </IconButton>
            </CardActions>
            <Collapse in={expand} timeout="auto" unmountOnExit>
                {children}
            </Collapse>
        </>
    );
}