import React, {useState, useEffect} from 'react';
import {useParams, useLocation} from 'react-router-dom';
import InfoPet from './InfoPet';
import Api from '../../workers/Api';
import { Typography, CircularProgress, Button} from '@material-ui/core';
import urlServer from '../../workers/urlServer';
import {Alert, AlertTitle} from '@material-ui/lab';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const Home = ()=>{
    let query = useQuery();
    let {idPet} = useParams();
    let [infoPet, setInfoPet] = useState(false);
    let [geoPosition, setGeoposition] = useState(false);
    useEffect(()=>{
        console.log('ID PET',idPet);
        getGeoposition();
        if(idPet){
        }
    },[]);
    const getGeoposition = ()=>{
        console.log('URL SERVER',);
        
        navigator.geolocation.getCurrentPosition(
            function(position) {
                const {latitude, longitude} = position.coords;
                console.log('POSITION',latitude, longitude);
                setGeoposition({latitude,longitude});
                getInfoPet(latitude,longitude);
              console.log(position);
            },
            function(error) {
                
              console.error("Error Code = " + error.code + " - " + error.message);
            }
        );
    }
    const getInfoPet = async (latitude,longitude)=>{
        if(idPet != undefined){
            console.log('GETTING INFO PET',idPet,latitude, longitude);
            let data = await Api.get('public/getInfoPet',{idPet,latitude, longitude});
            if(data.length > 0 ){
                setInfoPet(data[0]);
            }
            console.log('INFO PET',infoPet)
        }
    }
    const reloadPage = ()=>{
        window.location.reload();
    }
    return(
        <React.Fragment>
            <Typography className="nowrap" align="center" variant="h4">Canes&Michis</Typography>
            <Typography className="nowrap" align="center" variant="h5">QR ID</Typography>
            {!geoPosition?
            <Alert severity="warning">
                <Typography variant="h5" align="justify">
                    <AlertTitle>Aviso importante!</AlertTitle>
                    <CircularProgress color="secondary" size={20}/>
                    Por la seguridad de todas las MASCOTAS alojadas en Canes & Michis debes activar y aceptar los permisos de ubicacion para poder obtener la informacion esencial de cualquier mascota <br/> <br/>
                    Si ya activaste tu ubicacion recarga la pagina.
                </Typography>
                
                <Button variant="contained" color="primary" fullWidth={true} onClick={reloadPage}>Recargar pagina</Button>
            </Alert>:
            <InfoPet idPet={idPet} infoPet={infoPet} host={urlServer()}/>}
        </React.Fragment>
    )
}

export default Home;