import logo from './logo.svg';
import './App.css';
import {Switch, Route} from 'react-router-dom';
import Home from './components/Front/Home';
import Admin from './components/Admin/Admin';

function App() {
  return (
    <Switch>
      <Route exact path="/"><Home /></Route>
      <Route path="/home/pet/:idPet"><Home /></Route>
      <Route path="/admin"><Admin /></Route>
    </Switch>
  );
}

export default App;
