const axios = require('axios');
axios.defaults.baseURL = 'https://graciadev.com/michis/';
//axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.headers.common['x-access-token'] = '';
//axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

const instance = axios.create({
    baseURL: 'https://canesymichis.com/michis/'
})

const Api = {

    get: async (path ,params)=>{
        let {status, data} = await instance.get(path,{params})
        console.log('RESPONSE',status, data);
        if(status===200){
            if(data.response===2){
                return data.content;
            }else{
                return false;
            }
        }else{
    
        }
    
       
    },
    post: async (path, params, file=false)=>{
        let options = {};
        if(file){
            options = {headers:
                {
                    "Content-Type": "multipart/form-data"
                }
            } 
        }
        let {status, data} = await instance.post(path, params, options);
        if(status===200){
            if(data.response===2){
                return data.content;
            }else{
                return false;
            }
        }else{
    
        }
    }
}

export default Api;
