import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import {CardHeader, CardMedia, CardContent, CardActions, Collapse, Avatar, IconButton, Typography} from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import QRCode from "qrcode.react";
import { Grid } from '@material-ui/core';
import CarouselInfoPet from './InfoPetAtoms/CarouselInfoPet';
import ExpandSection from './InfoPetAtoms/ExpandSection';


const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

export default function RecipeReviewCard({idPet=0, infoPet=false, host}) {
  const classes = useStyles();
  useEffect(()=>{
    //console.log('info pet',infoPet);
  },[])
  const valueQR = host+'home/pet/'+idPet;
  return (
    <Grid container justifyContent="center">
        <Grid item container justifyContent="center" xs={12}>        
            {infoPet&&
            <Card className={classes.root}>
                {infoPet.images !== '' && infoPet.images !== null ? <CardMedia component = {()=> <CarouselInfoPet images={infoPet.images.split(',')} />} /> : <></>}
                <ExpandSection title='Info Mascota' expanded={true}>
                  <CardHeader
                      avatar={<Avatar aria-label="recipe" className={classes.avatar}>{infoPet.name[0]}</Avatar>}
                      action={<IconButton aria-label="settings"><MoreVertIcon /></IconButton>}
                      title={infoPet.name}
                      subheader={<a href={"tel:"+infoPet.tel}>{infoPet.tel}</a>}
                  />
                  <CardContent>
                      <Typography variant="body2" color="textSecondary" component="p">
                          {infoPet.race}
                      </Typography>
                  </CardContent>
                </ExpandSection>
                <ExpandSection title='QR' >
                  <CardMedia
                    component={()=>idPet === 0 ? <></> : <QRCode value={valueQR} size={350} format={"svg"}/>}
                    className={classes.media}
                    title="Pet Gallery"
                  />
                </ExpandSection>
            </Card>
            }
        
        </Grid>
    </Grid>
  
  );
}
