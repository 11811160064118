import React from 'react';
import Carousel from 'react-material-ui-carousel';
import { uploadsUrl } from '../../core/CONSTANTS';

const CarouselInfoPet = ({images=[]})=>{
    return(
        <>
            <Carousel>
                {images.map((el, i)=>
                    <img  className='imgPreview' src={uploadsUrl+el} alt='imagen' key={i} />
                )}
            </Carousel>
        </>
    );
}
export default  CarouselInfoPet;