import React, {useState, useEffect} from 'react';
import { Box, Button, CircularProgress, Divider, Grid, Modal, TextField, Typography } from '@material-ui/core';
import {useParams, useRouteMatch, useLocation} from 'react-router-dom';
import IconCancel from '@material-ui/icons/Cancel';
import Api from '../../workers/Api';
import TableData from './TableView';
import { DEPRECATED_KEYS } from '@babel/types';
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const FormBuilder = (props)=>{
    let query = useQuery();
    const uploadUrl = 'https://canesymichis.com/michis/uploads/pets/';
    let match = useRouteMatch();
    const {idTable} = useParams();
    const [id, setId] = useState(0);
    let [filters, setFilters] = useState(false);
    let [mounted, setMounted] = useState(false);
    const [open, setOpen] = useState(true);
    let [model, setModel] = useState([]); //MODEL DATA FORM
    let [dataSaved, setDataSaved] = useState([]);
    let [headsTable, setHeadsTable] = useState([]);
    useEffect(()=>{
        //getData();
    },[]);
    useEffect(()=>{
        console.log('MODEL CHANGED EVENT',mounted, model);
        if(!mounted&&model.length>0){
            setMounted(true);
            
            setValFilters();
        }
    },[model])
    useEffect(()=>{
        if(idTable){
            setMounted(false);
            getData();
        }

    },[idTable]);
    useEffect(()=>{
        if(id!=0){  
            getSaveDataSingle();
        }
    },[id])
    useEffect(()=>{
        if(filters){  
            getSavedData();
        }
    },[filters])
    const buildParamsToGet = async ()=>{
        let fields = [];
        for(var i in model){
            const d = model[i];
            if(d.showInTable){
                fields.push(d.id);
            }
        }
        fields = fields.join(',');
        return fields;
    };
    const editVal = async(id)=>{
        console.log('SET ID',id);
        setId(id);
    }
    const getData = async()=>{
        setOpen(true);
        console.log('MODEL TO GET',idTable);
        let model_ = await Api.get('admin/getModel',{model:idTable});
        if(model_.length > 0){
            console.log('MODEL__',model_);
            const TH = [...model_];
            setHeadsTable(TH);
            setModel(model_);
            setOpen(false);
        }
    }
    const getFilters = async(fields)=>{
        console.log('BUILD FITERS',fields)
        const F = fields.split(',');
        const a = {};
        for(var i in F){
            let field = F[i];
            let val = query.get(field);
            if(val){
                a[field] = val;
            }
        }
        return a;

    }
    const getSavedData = async()=>{
        const fields = await buildParamsToGet();
        console.log('GETTING DATA OF', idTable, ' - FIELDs',fields, '-- FILTERS', filters);
        if(fields!=''){
            setDataSaved([]);
            let data = await Api.get('admin/getTable',{fields,filters,table: idTable});
                setDataSaved(data);
        }
    }
    const getSaveDataSingle = async()=>{
        setOpen(true);
        const fields = await buildParamsToGet();
        const a = {...filters};
        a.id = id;
        console.log('GETTING DATA SINGLE OF', idTable, ' - FIELDs',fields, '-- FILTERS', filters);
        if(fields!=''){
            let data = await Api.get('admin/getSingleRow',{fields,id,table: idTable});
            if(data.length > 0){
                data = await data[0];
                let prev = [...model];
                prev.map((el,i)=>{
                    if(data[el.id]){
                        let val = data[el.id];
                        if(el.type=='file'&& val!=''){
                            el.previews = val.split(',');
                        }
                        el.val = data[el.id];
                    }
                    prev[i] = el;
                });
                setModel(prev);
            }
        }
        setOpen(false);
    }
    const removePreload = ()=>{

    }
    const removeFile = async ({nameFile, index})=>{
        if(window.confirm("Mama estas segura de eliminar esta imagen? se eliminara completamente del servidor")){

            console.log('Remove file data', nameFile, index, id);
            setOpen(true);
            const removeData = await Api.get('admin/removeFileFromPet',{nameFile, key: index, id, model: idTable});
            const newFileList = removeData.split(',');
            let state = [...model];
            for(const i in state){
                if(index === state[i].id){
                    state[i].previews = newFileList;
                }
            }
            setModel(state);
            setOpen(false);
            console.log('remove data result', removeData);
        }
    }
    const saveForm = async ()=>{
        let params = {};
        let files = {};
        let id = 0;
        let hasFiles = false;
        for(var i in model){
            let a = model[i];
            if(a.id==='id'){
                id = a.val;
            }
            if(a.type==='text'){
                params[a.id] = a.val;
            }
            if(a.type==='file'){
                hasFiles = a.preload.length > 0;
                files[a.id] = a.val;
            }
        }
        setOpen(true);
        const response = await  Api.post('/admin/saveForm',{id, params, table: idTable});
        if(hasFiles){
            console.log('FILES',files);
            updaloadFile({files, id, table: idTable});
        }
        if(id===0){
            setDataSaved([...dataSaved,...response]);
        }else{
            let prev = [...dataSaved];
            let a = [];
            await prev.map((el)=>{
                //console.log('EL',el.id, response[0].id);
                a.push(el.id===response[0].id ? response[0] : el);
                return false;
            });
            setDataSaved(a);
        }
        setOpen(false);
        console.log('DATA SAVED', response);
    }
    const setValFilters =async()=>{
        
        const fields = await buildParamsToGet();
        const filters_ = await getFilters(fields);
        console.log('FILTERS BUILD',filters_);
        setFilters(filters_);
        let prev = [...model];
        prev.map((el,i)=>{
            if(filters_[el.id]){
                el.val = filters_[el.id];
            }
            prev[i] = el;
        });
        setModel(prev);
    }
    const updateVal = async (e)=>{
        let prev = [...model];
        const name = e.target.name;
        let val = e.target.value;
        let files = false;
        if(e.target.type === 'file'){
            val = e.target.files;
            files = await handleChangeFile(e);
        }
        await prev.map((el,i)=>{
            if(el.id===name){
                el.val = val;
                if(!!files){
                    el.preload = files;
                }
            }
            return prev[i] = el;
        });
        setModel(prev);
    };
    const updaloadFile = async ({files, id, table})=>{
        setOpen(true);
        console.log('Data saved',files, id, table);  
        let data = new FormData();
        let filesData = files;
        let keys = [];
        console.log('FilesData',filesData);
        for(let i in filesData){
            const files = filesData[i];
            for(let j=0; j< files.length; j++){
                const file = files[j];
                const date = new Date();
                const nameFile = table+'_'+id+'_'+j+'_'+date.getTime();
                console.log('File to push',file, nameFile);
                data.append(nameFile,file);
                keys.push(nameFile);
            }
        }
        data.append('keys', keys);
        data.append('table',table);
        data.append('id',id);

        const updaLoadFiles = await Api.post('/admin/uploadFiles',data,true);
        console.log('response upload files',updaLoadFiles);
        setOpen(false);
    }
    const handleChangeFile = async (e)=>{
        let files = [];
        console.log('Change evnet files',e, e.target.name);
        let FILES = e.target.files;
        console.log('File',FILES);
        for(let i=0; i < FILES.length; i++){
            let f = e.target.files[i];
            console.log('FILE',f);
            let file = URL.createObjectURL(f);
            files.push(file);
        }
        return files;
        /* let state = {...previewImageGroups};
        if(state[e.target.name] !== undefined){
            state[e.target.name] = [];
        }
        state[e.target.name] = files;
        console.log('')
        setPreviewImageGroups(state); */
    }
    const handleCloseModal = ()=>{
        setOpen(false);
    }
    return (
        <React.Fragment>
            <h1>{idTable}</h1>
            <h2>{match.url}</h2>
            <h2>QUERY {JSON.stringify(filters)}</h2>
            {/* {JSON.stringify(model)} */}
            <Typography variant="h3">FILTERS: </Typography>
            <Grid container spacing={1}>
                <Grid item xs={12}></Grid>
                <Grid item xs={12} md={6} lg={6} >
                    <form noValidate>
                        {model.map((el, i)=>{
                            return(
                                <React.Fragment key={'input'+i}>
                                    {el.type !== 'collection' && el.type !== 'file'?(
                                        <Box>
                                            <TextField placeholder={el.place} autoComplete='off' label={el.place} disabled={el.disabled} fullWidth value={el.val}  name={el.id} type={el.type} onChange={updateVal} size="medium" />
                                        </Box>
                                    ):(el.type === 'file' ?
                                        (<Box p={1} pl={0} >
                                            <label for={'file_'+el.id} className='d-none'>
                                                <Button size="large" color='primary' variant='outlined' fullWidth> Añadir Imagenes</Button>
                                            </label>
                                            <input type="file" name={el.id} id={'file_'+el.id} onChange={updateVal} multiple/>
                                            <Box display='flex' pb={1} my={1} width={'100%'}  flexDirection='row' className='imagePreviewContent'>
                                                {el.previews!==undefined && el.previews.map((img,i)=>
                                                    <Box display='flex' className='relative' mr={1} key={i}>
                                                        <IconCancel className='btnCancelAbsoulte' onClick={()=>removeFile({index: el.id, nameFile: img})} />
                                                        <img className='imgPreview' src={uploadUrl+img} alt='imagen'/>
                                                    </Box>)
                                                }
                                                {el.preload!==undefined && el.preload.map((img,i)=>
                                                    <Box display='flex' className='relative' mr={1} key={el}>
                                                        <IconCancel className='btnCancelAbsoulte' />
                                                        <img className='imgPreview' src={img} alt='imagen'/>
                                                    </Box>)
                                                }
                                                <Box></Box>
                                            </Box>
                                            <Divider />
                                        </Box>):(<></>)
                                    )}
                                </React.Fragment>
                            )
                        })}
                        <Box mt={1}>
                            <Button variant="contained" color="primary" onClick={saveForm}>GUARDAR</Button>
                        </Box>    
                    </form>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                    <TableData fields={headsTable} data={dataSaved} table={idTable} editVal={editVal}/>
                </Grid>
            </Grid>
            <Modal open={open} onClose={handleCloseModal}>
                <CircularProgress className='centerAbsolute' color="secondary" />
            </Modal>
        </React.Fragment>
    )
}

export default FormBuilder;


