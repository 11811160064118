import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button } from '@material-ui/core';
import {useHistory, Link} from 'react-router-dom';
const StyledTableCell = withStyles((theme) => ({
  head: {
	backgroundColor: theme.palette.common.black,
	color: theme.palette.common.white,
  },
  body: {
	fontSize: 14,
  },
}))(TableCell);
const StyledTableRow = withStyles((theme) => ({
  root: {
	'&:nth-of-type(odd)': {
	  backgroundColor: theme.palette.action.hover,
	},
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
	minWidth: 700,
  },
});
export default function CustomizedTables({fields=[], data=[], table='', editVal}) {
	let history = useHistory();
	const goToPage = (path, blank=false)=>{
		if(!blank){

		}else{

		}
		history.push(path)
	}
	const classes = useStyles();
	//console.log('FIELDS',fields)
	//console.log('INFO',data)
	return (
		<TableContainer component={Paper}>
			<Table className={classes.table} aria-label="customized table">
			<TableHead>

				<TableRow>
					{fields&&fields.map((data)=>(
						(data.showInTable?<StyledTableCell key={'th_'+data.place}>{data.place}</StyledTableCell>:<td style={{display:'none'}} key={data.place}></td>)
					))}
					<StyledTableCell>
						OPS
					</StyledTableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{data.map((row,index) => (
					<StyledTableRow key={row.id}>
						{fields&&fields.map((d)=>(
						(d.showInTable?<StyledTableCell key={'td_'+d.id}>{row[d.id]}</StyledTableCell>:<React.Fragment key={'td_'+d.id}
						></React.Fragment>)
						))}
						<StyledTableCell>
							<Button variant="contained" color="primary" onClick={()=>editVal(row.id)}>EDITAR</Button>
							<Button variant="contained" color="secondary">ELIMINAR</Button>
							{table=='users'? <Button onClick={()=>{goToPage('/admin/form/pets?owner='+row.id)}} variant="contained" color="default">MASCOTAS</Button> : <></>}
							{table=='pets'? <Link to={'/home/pet/'+row.id} target="_blank"><Button variant="contained" color="default">QR</Button> </Link>: <></>}

						</StyledTableCell>
					</StyledTableRow>
				))}
			</TableBody>
			</Table>
		</TableContainer>
	);
}